import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(private http: HttpClient) {}
  getFollowUpQuestionFeature(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/ai/?partner_id=${id}`,
      {
        headers: header,
      }
    );
  }
  updateFollowUpQuestionFeature() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `/deincore/ats/integrations/send-followup-emails`,
      {
        headers: header,
      }
    );
  }

  getIntegrationsSettings() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/ats/integrations?is_active=true&page=1&page_size=100`,
      {
        headers: header,
      }
    );
  }
  getActiveJobsStatus(status: string) {
    const header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    const queryParams = `&status=${status}`;

    const url = `${environment.apiURL}deincore/partner/jobs/?status=${status}`;

    return this.http.get(url, { headers: header });
  }
  addIntegrationsSettings(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/ats/integration`,
      formData,
      {
        headers: header,
      }
    );
  }
  addGreenhouseIntegration(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/ats/integration`,
      formData,
      {
        headers: header,
      }
    );
  }
  addLeverIntegration(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/ats/integrations/complete-oauth-linking`,
      formData,
      {
        headers: header,
      }
    );
  }
  atsCreateLinkToken(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/ats/integrations/start-oauth-linking`,
      formData,
      {
        headers: header,
      }
    );
  }
  getAccountToken(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/ats/integrations/get-account-token`,
      formData,
      {
        headers: header,
      }
    );
  }
  getJobProcessingSettings() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/ai/job-processing`,
      {
        headers: header,
      }
    );
  }

  updateJobProcessingSettings(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/ai/job-processing`,
      formData,
      {
        headers: header,
      }
    );
  }
  followupQuestionsSettings() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/followup-questions-settings`,
      {
        headers: header,
      }
    );
  }
  updateFollowupQuestionsSettings(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/followup-questions-settings`,
      formData,
      {
        headers: header,
      }
    );
  }
  getWeebhook(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/ats/integration/webhook/${id}`,
      {
        headers: header,
      }
    );
  }
  postWeebhook(id: number, data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/ats/integration/webhook/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
  getJobRefinementUsers() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/ai/job-refinement`,
      {
        headers: header,
      }
    );
  }
  updateJobRefinementUsers(data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/ai/job-refinement`,
      data,
      {
        headers: header,
      }
    );
  }
}
