export class users {
  id: number = 0;
  user_id: number = 0;
  username: string = '';
  first_name: string = '';
  email: string = '';
  phone: string = '';
  role: string = '';
  is_active: boolean = false;
  user_profile?: user_profile;
  partner!: partner;
  isAssignedJob: boolean = false;
  profile_info!: profile_info;
  applications?: applications[] = [];
}
export class profile_info {
  email: string = '';
  first_name: string = '';
  last_name: string = '';
  remote_id: string = '';
  user_profile?: user_profile;
  website_addresses: website_addresses[] = [];
  addresses: addresses[] = [];
  phone_numbers: phone_numbers[] = [];
  user?: user;
  initials: string = '';
  applications: applications[] = [];
}

export class user {
  email: string = '';
  id: number = 0;
}
export class application {
  job_title: string = '';
  applied_at: string = '';
  remote_id: string = '';
  score: number = 0;
}
export class partner {
  id: number = 0;
  partner_name: string = '';
  phone_number: string = '';
}
export class user_profile {
  first_name: string = '';
  last_name: string = '';
  phone_number: string = '';
}
export class skillMapping {
  name: string = '';
  score: number = 0;
  maxScore: number = 0;
  summary: string = '';
}
export class partners {
  id: number = 0;
  partner_name: string = '';
  created_datetime: string = '';
}
export class candidates {
  id: number = 0;
  _id: string = '';
  remote_id: string = '';
  first_name: string = '';
  last_name: string = '';
  is_bookmarked: boolean = false;
  candidate_name: string = '';
  score: number = 0;
  email_addresses: email_addresses[] = [];
  phone_numbers: phone_numbers[] = [];
  addresses: addresses[] = [];
  website_addresses: website_addresses[] = [];
  jobs: jobs[] = [];
  jobs_applied: jobs_applied[] = [];
  applications: applications[] = [];
  follow_up_questions: follow_up_questions[] = [];
  comments: comments[] = [];
  resume_fields!: resume_fields;
  application_profile!: application_profile;
  ats: string = '';
  candidate_remote_id: number = 0;
  dein_candidate_id: number = 0;
  candidate_id: number = 0;
  candidate_obj_id: string = '';
  merge_id: string = '';
  initials: string = '';
  candidate_initials: string = '';
  candidate_ats_url: string = '';
  updated_with_answer: string = '';
  status: string = '';
  answers_summary: string = '';
  screening_segment: string = '';
  application_materials: application_materials[] = [];
  links: links[] = [];
  metrics!: metrics;
}
export class links {
  link_url: string = '';
  link_name: string = '';
}
export class follow_up_questions {
  category: string = '';
  question: string = '';
  answer: string = '';
}
export class application_materials {
  filename: string = '';
  material_name: string = '';
  url: string = '';
}
export class application_profile {
  summary: string = '';
  learning_agility!: learning_agility;
  critical_thinking!: critical_thinking;
  distance_travelled!: distance_travelled;
  skill_mapping!: skill_mapping;
  education: education[] = [];
  experience: experience[] = [];
  requirements_match: requirements_match[] = [];
}
export class skill_mapping {
  education_score: number = 0;
  nice_to_have_exp_score: number = 0;
  nice_to_have_skill_score: number = 0;
  people_skill_score: number = 0;
  required_exp_score: number = 0;
  required_skill_score: number = 0;
}
export class requirements_match {
  score: number = 0;
  job_description: any | JobDescriptionObject = '';
  percentage_score: number = 0;
  is_match: boolean = false;
}
export interface JobDescriptionObject {
  degree: string;
  level: string;
  cgpa: number;
}
export class phone_numbers {
  value: string = '';
}
export class addresses {
  value: string = '';
}
export class website_addresses {
  value: string = '';
}
export class email_addresses {
  value: string = '';
  email_addresses_type: string = '';
}
export class jobs {
  title: string = '';
  status: string = '';
  remote_id: string = '';
}
export class jobs_applied {
  title: string = '';
  status: string = '';
  remote_id: string = '';
}
export class applications {
  score: number = 0;
  _id: string = '';
  job!: job;
  remote_id: string = '';
  applied_at: string = '';
  profile?: profile;
  application_profile?: application_profile;
  job_title: string = '';
  job_status: string = '';
  metrics!: metrics;
}
export class metrics {
  learning_agility!: learning_agility;
  critical_thinking!: critical_thinking;
  distance_travelled!: distance_travelled;
}

export class profile {
  fit_score: number = 0;
  learning_agility!: learning_agility;
  critical_thinking!: critical_thinking;
  distance_travelled!: distance_travelled;
}

export class learning_agility {
  candidate_recommendation: string = '';
  partner_recommendation: string = '';
  level: string = '';
}
export class critical_thinking {
  candidate_recommendation: string = '';
  partner_recommendation: string = '';
  level: string = '';
}
export class distance_travelled {
  candidate_recommendation: string = '';
  partner_recommendation: string = '';
}
export class job {
  remote_id: number = 0;
  _id: string = '';
  status: string = '';
  title: string = '';
  ats: string = '';
  posting_date: string = '';
}
export class comments {
  candidate: string = '';
  date_posted: string = '';
  text: string = '';
}
export class resume_fields {
  summary: string = '';
  linked_in: string = '';
  email: string = '';
  website: string = '';
  education: education[] = [];
  experience: experience[] = [];
}
export class education {
  institute: string = '';
  graduate_date: string = '';
  degree: string = '';
}
export class experience {
  company: string = '';
  position: string = '';
  start_date: string = '';
  end_date: string = '';
  description: string = '';
  level: string = '';
}
export class planDetails {
  active: boolean = false;
  created: string = '';
  marketing_features: marketing_features[] = [];
  name: string = '';
  url: string = '';
  customer_portal_url: string = '';
  threshold: string = '';
  status: string = '';
  stripe_customer: boolean = false;
}
export class marketing_features {
  name: string = '';
}
export class plans {
  payment_link_name: string = '';
  payment_link_url: string = '';
  line_items: line_items[] = [];
}
export class line_items {
  amount_total: string = '';
  description: string = '';
  marketing_features: marketing_features[] = [];
}
