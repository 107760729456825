import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';

@Component({
  selector: 'app-refine-job-listing',
  templateUrl: './refine-job-listing.component.html',
  styleUrls: ['./refine-job-listing.component.scss'], // corrected property name
})
export class RefineJobListingComponent implements OnInit {
  @Input() jobObjectId!: string;
  isEditMode: boolean = false;
  isVisiblePDF: boolean = false;
  user: any;
  jobForm!: FormGroup;
  posting_date: string = '';

  constructor(
    private router: Router,
    public jobsMgr: JobsManagerService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private notification: NzNotificationService,
    public applicationMgr: ApplicationManagerService
  ) {}

  ngOnInit() {
    this.jobForm = this.fb.group({
      responsibilities: this.fb.array([]),
      requiredExperience: this.fb.array([]),
      niceToHaveExperience: this.fb.array([]),
      requiredSkills: this.fb.array([]),
      niceToHaveSkills: this.fb.array([]),
      peopleSkills: this.fb.array([]),
      education: this.fb.group({
        degree: [''],
        status: [''],
        user_added: false,
      }),
    });
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    if (this.jobObjectId) {
      if (this.user.role === 'Recruiter') {
        this.jobsMgr.checkUserJobRequest(this.jobObjectId);
      }
      this.posting_date = this.applicationMgr.jobDetailData['posting_date'];

      this.jobsMgr.getJobRequirmentListing(this.jobObjectId);
    }
  }

  initializeForm(data: any) {
    this.responsibilities.clear();
    this.requiredExperience.clear();
    this.niceToHaveExperience.clear();
    this.requiredSkills.clear();
    this.niceToHaveSkills.clear();
    this.peopleSkills.clear();
    // Initialize responsibilities
    data.responsibilities.forEach((responsibility: any) => {
      this.responsibilities.push(
        this.fb.group({
          id: responsibility.id,
          level: responsibility.level,
          source: responsibility.source,
          description: [responsibility.description, Validators.required],
          status: [responsibility.status],
          user_added: [responsibility.user_added || false],
        })
      );
    });

    // Initialize required_experience with user_added
    data.required_experience.forEach((experience: any) => {
      this.requiredExperience.push(
        this.fb.group({
          id: experience.id,
          level: experience.level,
          source: experience.source,
          description: [experience.description, Validators.required],
          status: [experience.status],
          user_added: [experience.user_added || false], // Adding user_added
        })
      );
    });

    // Initialize nice_to_have_experience with user_added
    data.nice_to_have_experience.forEach((experience: any) => {
      this.niceToHaveExperience.push(
        this.fb.group({
          id: experience.id,
          level: experience.level,
          source: experience.source,
          description: [experience.description, Validators.required],
          status: [experience.status],
          user_added: [experience.user_added || false], // Adding user_added
        })
      );
    });

    // Initialize required_skills (if user_added is applicable, you can add it here as well)
    data.required_skills.forEach((skill: any) => {
      this.requiredSkills.push(
        this.fb.group({
          id: skill.id,
          level: skill.level,
          source: skill.source,
          description: [skill.description, Validators.required],
          status: [skill.status],
          user_added: [skill.user_added || false], // Assuming user_added is optional here
        })
      );
    });

    // Initialize nice_to_have_skills (if user_added is applicable, you can add it here as well)
    data.nice_to_have_skills.forEach((skill: any) => {
      this.niceToHaveSkills.push(
        this.fb.group({
          id: skill.id,
          level: skill.level,
          source: skill.source,
          description: [skill.description, Validators.required],
          status: [skill.status],
          user_added: [skill.user_added || false], // Assuming user_added is optional here
        })
      );
    });
    data.people_skills.forEach((skill: any) => {
      this.peopleSkills.push(
        this.fb.group({
          id: skill.id,
          level: skill.level,
          source: skill.source,
          description: [skill.description, Validators.required],
          status: [skill.status],
          user_added: [skill.user_added || false], // Assuming user_added is optional here
        })
      );
    });
    // Initialize education
    console.log('data education', data.education);
    this.jobForm.get('education')?.patchValue({
      cgpa: data.education.cgpa || 1,
      level: data.education.level || '',
      source: data.education.source || '',
      degree: data.education.degree || '',
      status: data.education.status || 'active',
      user_added: data.education.user_added || false,
    });
  }

  editJobRequirements() {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.initializeForm(this.jobsMgr.variables);
    }
  }

  requestRefinement() {
    this.jobsMgr.requestUserJobRefinementAccess(this.jobObjectId);
  }

  goToRefineJob() {
    this.router.navigate(['jobs/refine-job-requirements', this.jobObjectId]);
  }

  get responsibilities(): FormArray {
    return this.jobForm.get('responsibilities') as FormArray;
  }

  get requiredExperience(): FormArray {
    return this.jobForm.get('requiredExperience') as FormArray;
  }

  get niceToHaveExperience(): FormArray {
    return this.jobForm.get('niceToHaveExperience') as FormArray;
  }

  get requiredSkills(): FormArray {
    return this.jobForm.get('requiredSkills') as FormArray;
  }

  get niceToHaveSkills(): FormArray {
    return this.jobForm.get('niceToHaveSkills') as FormArray;
  }
  get peopleSkills(): FormArray {
    return this.jobForm.get('peopleSkills') as FormArray;
  }

  addItemToArray(formArray: FormArray, arrayName: string): void {
    const currentItems = formArray.value;
    const lastId =
      currentItems.length > 0
        ? Math.max(...currentItems.map((item: any) => item.id))
        : 0; // Get the highest id, or 0 if the array is empty

    formArray.insert(
      0,
      this.fb.group({
        id: lastId + 1, // Increment the last id by 1
        description: ['', Validators.required],
        status: ['active'],
        user_added: [true],
      })
    );
  }

  // Example usage
  addResponsibility(): void {
    this.addItemToArray(this.responsibilities, 'responsibilities');
  }

  addRequiredExperience(): void {
    this.addItemToArray(this.requiredExperience, 'requiredExperience');
  }

  addNiceToHaveExperience(): void {
    this.addItemToArray(this.niceToHaveExperience, 'niceToHaveExperience');
  }

  addRequiredSkill(): void {
    this.addItemToArray(this.requiredSkills, 'requiredSkills');
  }

  addNiceToHaveSkill(): void {
    this.addItemToArray(this.niceToHaveSkills, 'niceToHaveSkills');
  }

  addPeopleSkill(): void {
    this.addItemToArray(this.peopleSkills, 'peopleSkills');
  }

  addEducation() {
    // Set default values for education when adding one
    this.jobForm.get('education')?.patchValue({
      degree: '',
      status: 'active',
      user_added: true,
    });
  }
  // ========= Remove ===========
  removeResponsibility(index: number): void {
    this.responsibilities.removeAt(index);
  }
  removedRequiredExperience(index: number): void {
    this.requiredExperience.removeAt(index);
  }
  removeNiceToHaveExperience(index: number): void {
    this.niceToHaveExperience.removeAt(index);
  }
  removeRequiredSkill(index: number): void {
    this.requiredSkills.removeAt(index);
  }
  removeNiceToHaveSkill(index: number): void {
    this.niceToHaveSkills.removeAt(index);
  }
  removePeopleSkill(index: number): void {
    this.peopleSkills.removeAt(index);
  }
  // Function to set the status to 'active'
  setActive(control: AbstractControl): void {
    control.patchValue({ status: 'active' });
  }

  // Function to set the status to 'hide'
  setHide(control: AbstractControl): void {
    control.patchValue({ status: 'hide' });
  }
  toggleStatus(control: any): void {
    const currentStatus = control.get('status')?.value;
    if (currentStatus === 'active') {
      this.setHide(control);
    } else {
      this.setActive(control);
    }
    this.cdr.detectChanges();
  }
  async submitJobRequirements(): Promise<void> {
    if (this.jobForm.valid) {
      const payload = {
        responsibilities: this.responsibilities.value,
        required_experience: this.requiredExperience.value,
        nice_to_have_experience: this.niceToHaveExperience.value,
        required_skills: this.requiredSkills.value,
        nice_to_have_skills: this.niceToHaveSkills.value,
        people_skills: this.peopleSkills.value,
        education: this.jobForm.get('education')?.value,
      };
      // console.log('hi', payload);
      //return;
      await this.jobsMgr.updateJobRequirmentFeilds(this.jobObjectId, payload);
      this.isEditMode = false;
    } else {
      this.notification.create('error', 'Error', 'Fill out the description!');
    }
  }
  viwePDFModal(): void {
    this.isVisiblePDF = true;
  }
  handleOk(): void {
    this.isVisiblePDF = false;
  }
  ngOnDestroy() {
    this.jobsMgr.variables = null;
  }
}
