import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { CandidatesManagerService } from 'src/app/Managers/candidates-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { CommentsManagerService } from 'src/app/Managers/comments-manager.service';
import { follow_up_questions } from 'src/app/models/users';

import { Router } from '@angular/router';

@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrl: './candidate-profile.component.scss',
})
export class CandidateProfileComponent {
  isScoreLevelsModal: boolean = false;
  scoreData: any = {};
  scoreType: string = '';
  @Input()
  candidateId!: string;
  @Input()
  atsName!: string;
  application_obj_id: string = '';
  @Output() okClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();
  isCollapseEducation = false;
  isCollapseExperience = false;
  isCollapseFollowUpQuestions = false;
  isApplicationMaterials = false;
  listOfOption: string[] = [];
  listOfSelectedValue = ['a10', 'c12'];
  groupedQuestions: { [key: string]: follow_up_questions[] } = {};
  user: any;

  isVisible = false;
  isVisibleShareCandidate = false;
  constructor(
    public candidateMgr: CandidatesManagerService,
    private router: Router,
    public applicationMgr: ApplicationManagerService,
    public commentMgr: CommentsManagerService,
    private elRef: ElementRef
  ) {}
  openScoreLevelModal(data: any, type: string): void {
    this.scoreData = data;
    this.scoreType = type;
    this.isScoreLevelsModal = true;
  }
  handleCancelModal(): void {
    this.isScoreLevelsModal = false;
  }
  replaceUnderscoreAndCapitalize(key: string): string {
    return key.replace(/_/g, ' ').toUpperCase();
  }

  shareCandidateModal(): void {
    this.isVisibleShareCandidate = true;
  }
  handleCancelShareCandidate(): void {
    this.isVisibleShareCandidate = false;
  }
  openApplicationMaterials(): void {
    this.isApplicationMaterials = !this.isApplicationMaterials;
  }
  onCandidateIdChange(id: string, adjustAIWeights: boolean): void {
    this.application_obj_id = id;
    //this.candidateMgr.getAppliationProfile(id, adjustAIWeights);
    this.commentMgr.getCommentsApplications(this.application_obj_id);
  }
  collapseEducation(): void {
    this.isCollapseEducation = !this.isCollapseEducation;
  }
  collapseExperience(): void {
    this.isCollapseExperience = !this.isCollapseExperience;
  }
  collapseFollowUpQuestions(): void {
    this.isCollapseFollowUpQuestions = !this.isCollapseFollowUpQuestions;
    this.groupQuestionsByCategory();
  }
  groupQuestionsByCategory() {
    this.groupedQuestions =
      this.candidateMgr.applicationProfile.follow_up_questions.reduce(
        (
          acc: { [key: string]: follow_up_questions[] },
          question: follow_up_questions
        ) => {
          if (!acc[question.category]) {
            acc[question.category] = [];
          }
          acc[question.category].push(question);
          return acc;
        },
        {}
      );
  }

  objectKeysFiltered(obj: any): string[] {
    this.groupQuestionsByCategory();
    return Object.keys(obj);
  }
  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
  downloadApplicationProfile(id: string, candidate_name: string): void {
    this.candidateMgr.downloadApplicationProfile(id, candidate_name);
  }
  goToCandidatePage(id: any) {
    id && this.router.navigate(['candidates', id]);
  }
  getBgColor(index: number): string {
    const colors = [
      '#029c6a',
      '#06b078',
      '#0ac084',
      '#0fd795',
      '#26db9f',
      '#3edfaa',
    ];
    return colors[index % colors.length];
  }

  getScoreName(name: string): string {
    switch (name) {
      case 'nice_to_have_skill':
        return 'Nice to have skills';
      case 'required_skill':
        return 'Required skills';
      case 'people_skill':
        return 'People skills';
      default:
        return name;
    }
  }
  toggleBookmark(applicationProfile: any): void {
    applicationProfile.is_bookmarked = !applicationProfile.is_bookmarked;

    if (applicationProfile.is_bookmarked) {
      this.bookmarkApplication(applicationProfile.candidate_obj_id);
    } else {
      this.removeBookmarkApplication(applicationProfile.candidate_obj_id);
    }
  }
  async bookmarkApplication(id: string): Promise<void> {
    await this.applicationMgr.bookmarkApplication(id);
  }
  async removeBookmarkApplication(id: string): Promise<void> {
    await this.applicationMgr.removeBookmarkApplication(id);
  }
  getFullUrl(url: string): string {
    if (!url) return '#';
    const hasProtocol = /^(http:\/\/|https:\/\/)/i.test(url);
    return hasProtocol ? url : `http://${url}`;
  }
  isPdf(filename: string): boolean {
    return filename.toLowerCase().endsWith('.pdf');
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }
}
