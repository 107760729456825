<nz-spin [nzSpinning]="this.candidateMgr.isLoadingProfile">
    <div class="profile-panel" *ngIf="candidateMgr.applicationProfile">
        <div class="profile-header">
            <div class="user-info">
                <div *ngIf="isApplicationMaterials" class="d-flex align-middle">
                    <button (click)="openApplicationMaterials()" class="btn-icon mr-8" nzShape="round"><span nz-icon
                            nzType="arrow-left" nzTheme="outline"></span></button>
                    <div class="font-weight-medium mr-8">Application Materials</div>
                </div>
                <h3>{{candidateMgr.applicationProfile.candidate_initials }}
                </h3>
                <div class="id-label">ID
                    {{candidateMgr.applicationProfile.remote_id ? candidateMgr.applicationProfile.remote_id :
                    candidateMgr.applicationProfile['_id'] }}</div>
                <div class="btn-default-icon ml-4" *ngIf="candidateMgr.applicationProfile.updated_with_answer"
                    nzTooltipTitle="Applicant score has been re-calculated after the submission of their follow-up answers."
                    [nzTooltipPlacement]="['topLeft', 'leftTop']" nz-tooltip>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 1.333A4.667 4.667 0 0 1 8.13 3.827 4.327 4.327 0 0 1 11.667 2H14v1.667A4.333 4.333 0 0 1 9.667 8h-1v.667H12v4.666c0 .737-.597 1.334-1.333 1.334H5.333A1.333 1.333 0 0 1 4 13.333V8.667h3.333V7.333H6a4.667 4.667 0 0 1-4.667-4.666V1.333H4zM10.667 10H5.333v3.333h5.334V10zm2-6.667h-1a3 3 0 0 0-3 3v.334h1a3 3 0 0 0 3-3v-.334zM4 2.667H2.667A3.334 3.334 0 0 0 6 6h1.333A3.334 3.334 0 0 0 4 2.667z"
                            fill="#B09EE5" />
                    </svg>
                </div>
                <div class="hired-label"
                    *ngIf="candidateMgr.applicationProfile.status && candidateMgr.applicationProfile.status === 'hired'">
                    <div nz-icon nzType="check" class="ico-label" nzTheme="outline"></div>
                    <div>Hired</div>
                </div>

            </div>
            <div class="d-flex align-middle ml-4" *ngIf="!isApplicationMaterials">
                <div class="header-actions">
                    <div *ngIf="user.role !== 'Hiring Manager'">
                        <button class="btn-icon mr-8" [class.active]="candidateMgr.applicationProfile.is_bookmarked"
                            (click)="toggleBookmark(candidateMgr.applicationProfile)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.167 1.667h11.666a.833.833 0 0 1 .834.833v15.953a.416.416 0 0 1-.639.353L10 15.025l-6.028 3.78a.417.417 0 0 1-.639-.352V2.5a.833.833 0 0 1 .834-.833zM15 3.333H5v12.86l5-3.134 5 3.134V3.333z"
                                    fill="#1C1A20" fill-opacity=".7" />
                            </svg>
                        </button>
                    </div>


                    <button *ngIf="user.role !== 'Hiring Manager'" nz-typography nzCopyable
                        [nzCopyText]="candidateMgr.applicationProfile.resume_fields && candidateMgr.applicationProfile.resume_fields.email"
                        [nzCopyTooltips]="['Copy Email', 'Email Copied']" [nzCopyIcons]="[copedIcon, copedIcon]"
                        class="btn-icon mr-8" nzShape="round"></button>
                    <ng-template #copedIcon>
                        <svg class="dark-icon" width="20" height="20" viewBox="0 0 20 20" fill="#747277"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#n0a14iip8a)">
                                <path
                                    d="M18.333 10.833h-1.666V6.032l-6.607 5.916-6.727-5.935v9.82h8.334V17.5H2.5a.833.833 0 0 1-.833-.833V3.333A.833.833 0 0 1 2.5 2.5h15a.833.833 0 0 1 .833.833v7.5zM3.76 4.167l6.292 5.551 6.2-5.551H3.76zM17.499 15H20v1.667h-2.5v2.5h-1.666v-2.5h-2.5V15h2.5v-2.5H17.5V15z"
                                    fill="#747277" />
                            </g>
                            <defs>
                                <clipPath id="n0a14iip8a">
                                    <path fill="#fff" d="M0 0h20v20H0z" />
                                </clipPath>
                            </defs>
                        </svg>
                    </ng-template>
                    <a *ngIf="user.role !== 'Hiring Manager'" [href]="candidateMgr.applicationProfile.candidate_ats_url"
                        target="_blank">
                        <button class="standard-button mr-8"
                            *ngIf="candidateMgr.applicationProfile.candidate_ats_url">Open in ATS</button>
                    </a>
                    <button *ngIf="user.role !== 'Hiring Manager'" class="standard-button mr-8"
                        (click)="openApplicationMaterials()">Application
                        Materials&nbsp;&nbsp;<span nz-icon nzType="arrow-right" nzTheme="outline"></span></button>
                    <div>
                        <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nz-button nzType="default"
                            nzShape="circle" nzPlacement="bottomRight"><span nz-icon nzType="more"
                                nzTheme="outline"></span></button>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                                <li *ngIf="user.role !== 'Hiring Manager'"
                                    (click)="goToCandidatePage(candidateMgr.applicationProfile.candidate_obj_id)"
                                    nz-menu-item>
                                    Go to Candidate Profile</li>
                                <li (click)="downloadApplicationProfile(candidateMgr.applicationProfile._id,candidateMgr.applicationProfile.candidate_name)"
                                    nz-menu-item>
                                    Download Profile PDF</li>

                            </ul>
                        </nz-dropdown-menu>
                    </div>

                </div>
            </div>
        </div>
        <div class="tags-user-links">
            <nz-tag
                *ngIf="candidateMgr.applicationProfile.resume_fields && candidateMgr.applicationProfile.resume_fields.linked_in">
                <a target="_blank"
                    [href]="getFullUrl(candidateMgr.applicationProfile.resume_fields.linked_in)">LinkedIn</a>
            </nz-tag>
            <nz-tag
                *ngIf="candidateMgr.applicationProfile.resume_fields && candidateMgr.applicationProfile.resume_fields.website">
                <a target="_blank"
                    [href]="getFullUrl(candidateMgr.applicationProfile.resume_fields.website)">Website</a>
            </nz-tag>
            <div *ngIf="candidateMgr.applicationProfile.links && candidateMgr.applicationProfile.links.length > 0">
                <nz-tag *ngFor="let link of candidateMgr.applicationProfile.links">
                    <a target="_blank" [href]="getFullUrl(link.link_url)">{{ link.link_name }}</a>
                </nz-tag>
            </div>
        </div>
        <div *ngIf="!isApplicationMaterials">
            <p><strong>Match Scores</strong></p>
            <div class="application-item">
                <div class="progress">
                    <ng-container *ngFor="let item of candidateMgr.skillMapping; let i = index; last as isLast">
                        <div class="bar-inner"
                            [ngStyle]="{'width': item.score + '%', 'background': getBgColor(i), 'border-radius': isLast ? '0 12px 12px 0' : '0'}">
                        </div>
                    </ng-container>

                    <div class="progress-label"><span
                            class="font-lg">{{candidateMgr.applicationProfile.score}}</span><br><span
                            class="font-light">CLARA Score</span>
                    </div>
                </div>

            </div>

            <div nz-row [nzGutter]="5" class="mb-20">
                <ng-container *ngFor="let item of candidateMgr.skillMapping ; let i = index">
                    <div nz-col>
                        <div class="bar-widget">
                            <div class="bar-total"
                                [ngStyle]="{'width': item.maxScore * 5 + 'px', 'background': '#f2f2f8'}">
                                <div class="bar-widget-rec"
                                    [ngStyle]="{'width': item.score * 5 + 'px', 'background': getBgColor(i)}">
                                </div>

                            </div>
                            <div class="bar-widget-text">
                                <h4>{{ item.score }}
                                    <span *ngIf="item.maxScore" class="sm-value">/{{item.maxScore}}
                                        <svg class="ico-info" nz-popover [nzPopoverContent]="contentTemplate"
                                            nzPopoverPlacement="topLeft" width="17" height="16" viewBox="0 0 17 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.428 14.667a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334zm0-1.334a5.334 5.334 0 1 0 0-10.667 5.334 5.334 0 0 0 0 10.667zm-.666-8.666h1.333V6H7.762V4.667zm0 2.666h1.333v4H7.762v-4z"
                                                fill="#1C1A20" fill-opacity=".7" />
                                        </svg>
                                    </span>

                                </h4>
                                <p>{{ getScoreName(item.name) | replaceUnderscore }}</p>
                                <ng-template #contentTemplate>
                                    <div class="info-popover color-black">
                                        {{item.summary ? item.summary : 'No summary available!'}}
                                    </div>
                                </ng-template>
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="no-additional-insight" *ngIf="candidateMgr.applicationProfile.metrics === null">
                <div class="ico-white">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5 1.667a5.834 5.834 0 0 1 5.164 3.117 5.41 5.41 0 0 1 4.42-2.284H17.5v2.083A5.417 5.417 0 0 1 12.083 10h-1.25v.833H15v5.834c0 .92-.746 1.666-1.667 1.666H6.667c-.921 0-1.667-.745-1.667-1.666v-5.834h4.167V9.167H7.5a5.833 5.833 0 0 1-5.833-5.834V1.667H5zM13.333 12.5H6.667v4.167h6.666V12.5zm2.5-8.333h-1.25a3.75 3.75 0 0 0-3.75 3.75v.416h1.25a3.75 3.75 0 0 0 3.75-3.75v-.416zM5 3.333H3.333C3.333 5.634 5.2 7.5 7.5 7.5h1.667C9.167 5.2 7.3 3.333 5 3.333z"
                            fill="#1C1A20" fill-opacity=".6" />
                    </svg>
                </div>
                <div>
                    <p><b>No Additional Insights Available</b></p>
                    <p>{{candidateMgr.applicationProfile.candidate_initials }} has not yet submitted their follow-up
                        answers.</p>
                </div>
            </div>
            <div class="mb-20" *ngIf="candidateMgr.applicationProfile.metrics">
                <p><strong>Additional insights</strong></p>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12">
                        <div class="score-panel"
                            (click)="openScoreLevelModal(candidateMgr.applicationProfile.metrics.learning_agility,'Learning Agility')">
                            <div class="score-panel-header">
                                <div class="score-level flex-direction-column">
                                    <b>{{candidateMgr.applicationProfile.metrics.learning_agility.level
                                        | titlecase}}</b>
                                    <span>Learning Agility</span>
                                </div>
                                <div class="ico-link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                            fill="#1C1A20" fill-opacity=".6" />
                                    </svg>

                                </div>
                            </div>
                            <div class="score-level-progress bg-green"
                                [ngClass]="candidateMgr.applicationProfile.metrics.learning_agility.level | lowercase">
                            </div>
                        </div>



                    </div>
                    <div nz-col [nzSpan]="12">
                        <div class="score-panel"
                            (click)="openScoreLevelModal(candidateMgr.applicationProfile.metrics.critical_thinking,'Critical Thinking')">
                            <div class="score-panel-header">
                                <div class="score-level flex-direction-column">
                                    <b>{{candidateMgr.applicationProfile.metrics.critical_thinking.level
                                        | titlecase}}</b>
                                    <span>Critical Thinking</span>
                                </div>
                                <div class="ico-link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                            fill="#1C1A20" fill-opacity=".6" />
                                    </svg>

                                </div>
                            </div>
                            <div class="score-level-progress bg-green"
                                [ngClass]="candidateMgr.applicationProfile.metrics.critical_thinking.level | lowercase">
                            </div>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="24">
                        <div class="score-panel"
                            (click)="openScoreLevelModal(candidateMgr.applicationProfile.metrics.distance_travelled,'Distance Traveled')">
                            <div class="score-panel-header">
                                <div class="score-level flex-direction-column">
                                    <span>Distance Traveled</span>
                                </div>
                                <div class="ico-link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                            fill="#1C1A20" fill-opacity=".6" />
                                    </svg>

                                </div>
                            </div>
                            <div class="score-panel-body">
                                {{candidateMgr.applicationProfile.metrics.distance_travelled.partner_recommendation
                                | titlecase}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="requirements">
                <p><strong>Requirements Match</strong></p>
                <div class="requirement-item"
                    *ngFor="let item of candidateMgr.applicationProfile.application_profile?.requirements_match">
                    <p *ngIf="item.is_match">
                        <span nz-icon nzType="check" class="icon primary-color" nzTheme="outline"></span>
                        <span>{{ isObject(item.job_description) ? item.job_description.degree :
                            item.job_description
                            }}</span>
                    </p>
                </div>

            </div>
            <div class="summary" *ngIf="candidateMgr.applicationProfile.resume_fields">
                <p><strong>Summary</strong></p>
                <div class="color-black">{{candidateMgr.applicationProfile.resume_fields.summary ?
                    candidateMgr.applicationProfile.resume_fields.summary : 'No summary available!'}}</div>

            </div>
            <div class="summary" *ngIf="candidateMgr.applicationProfile.answers_summary">
                <p><strong>Follow-up Questions Summary</strong></p>
                <div class="color-black">{{candidateMgr.applicationProfile.answers_summary ?
                    candidateMgr.applicationProfile.answers_summary : 'No summary available!'}}</div>
            </div>
            <div class="resume-item color-black"
                *ngIf="candidateMgr.applicationProfile.follow_up_questions && candidateMgr.applicationProfile.follow_up_questions.length > 0">
                <h4 (click)="collapseFollowUpQuestions()">Follow-Up Questions and Answers
                    <span nz-icon [nzType]="isCollapseFollowUpQuestions ? 'caret-down' : 'caret-up'"></span>
                </h4>
                <div *ngIf="isCollapseFollowUpQuestions">
                    <div *ngFor="let category of objectKeysFiltered(groupedQuestions)">
                        <div class="follow-up-question">
                            <div><b>{{ category | titlecase }}</b></div>
                            <div *ngFor="let item of groupedQuestions[category]; let i = index">
                                <div>
                                    <p>Question {{ i + 1 }}: {{ item.question }}</p>
                                    <p *ngIf="item.answer">Answer {{ i + 1 }}: {{ item.answer }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="resume-item color-black"
                *ngIf="candidateMgr.applicationProfile.resume_fields && candidateMgr.applicationProfile.resume_fields.experience && candidateMgr.applicationProfile.resume_fields.experience.length > 0">
                <h4 (click)="collapseExperience()">Experience<span nz-icon
                        [nzType]="isCollapseExperience ? 'caret-down' : 'caret-up'"></span></h4>
                <div *ngIf="isCollapseExperience">
                    <div *ngFor="let exp of candidateMgr.applicationProfile.resume_fields.experience">
                        <div class="experience-bar">
                            <div class="experience-body">
                                <span>{{exp.position}}</span>
                                <span>{{exp.company}}</span>
                                <!-- <span>{{exp.level}}</span> -->
                                <span>{{exp.start_date}} - {{exp.end_date}}</span>
                            </div>
                        </div>
                        <div class="experience-list">
                            {{exp.description}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="resume-item color-black"
                *ngIf="candidateMgr.applicationProfile.resume_fields && candidateMgr.applicationProfile.resume_fields.education && candidateMgr.applicationProfile.resume_fields.education.length > 0">
                <h4 (click)="collapseEducation()">Education <span nz-icon
                        [nzType]="isCollapseEducation ? 'caret-down' : 'caret-up'"></span>
                </h4>
                <nz-list *ngIf="isCollapseEducation">
                    <nz-list-item *ngFor="let edu of candidateMgr.applicationProfile.resume_fields.education">
                        <span class="color-black">{{edu.institute}}</span>
                        <span class="color-black">{{edu.degree}}</span>
                        <span class="color-black" *ngIf="edu.degree">{{edu.graduate_date}}</span>
                    </nz-list-item>
                </nz-list>
            </div>
            <div *ngIf="candidateMgr.applicationProfile && candidateMgr.applicationProfile['_id']">
                <h4><b>Comments</b></h4>
                <app-comments-application
                    [applicationId]="candidateMgr.applicationProfile['_id']"></app-comments-application>
            </div>
        </div>
        <div *ngIf="isApplicationMaterials" class="pdf-wrap loader-placeholder">
            <div
                *ngFor="let material of candidateMgr.applicationProfile && candidateMgr.applicationProfile.application_materials">
                <div *ngIf="isPdf(material.filename); else downloadLink">
                    <pdf-viewer class="pdf-viewer" [src]="material.url" [rotation]="0" [original-size]="false"
                        [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
                        [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
                        [autoresize]="true" [show-borders]="false" style="width: 100%; height: 100vh;"></pdf-viewer>
                </div>
                <ng-template #downloadLink>
                    <div class="download-links">
                        <a [href]="material.url" download="{{ material.filename }}">
                            Download {{ material.filename }}
                        </a>
                    </div>
                </ng-template>
            </div>

        </div>
        <nz-modal [(nzVisible)]="isScoreLevelsModal" [nzClosable]="false" [nzFooter]="null" nzWidth="572px" nzCentered
            [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="level-modal">

                    <div class="close" (click)="handleCancelModal()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m10 8.822 4.125-4.125 1.178 1.178L11.178 10l4.125 4.125-1.178 1.178L10 11.178l-4.125 4.125-1.178-1.178L8.822 10 4.697 5.875l1.178-1.178L10 8.822z"
                                fill="#1C1A20" fill-opacity=".6" />
                        </svg>

                    </div>

                    <h2 class="font-heading">{{ scoreType }}</h2>
                    <div *ngIf="scoreType === 'Learning Agility'">
                        <p>Learning Agility refers to the ability of taking in new information and applying it to
                            familiar situations or the
                            opposite scenario in which you use previously learned information and adapt and apply it to
                            a new situation.
                            This construct is made up of cognitive agility, people agility, change agility, results
                            agility and self-awareness.</p>

                        <p> It involves such things as cognitive agility, people agility, change agility, results
                            agility and self-awareness.</p>
                    </div>
                    <div *ngIf="scoreType === 'Critical Thinking'">
                        <p> Critical thinking means making well-reasoned decisions. We define critical thinking as the
                            intellectually disciplined
                            process of actively and skillfully conceptualizing, applying, analyzing, synthesizing,
                            and/or evaluating information
                            gathered from, or generated by, observation, experience, reflection, reasoning, or
                            communication, as a guide to belief
                            and action.</p>

                        <p> It involves processes and skills such as problem solving, analysis, evaluating, asking
                            questions, and inferring.</p>
                    </div>
                    <div *ngIf="scoreType === 'Distance Traveled'">
                        <p>Distance Traveled is a measure of an individual's accomplishments and capacities that
                            considers their starting point and
                            trajectory of progress. It reflects the sum of what they’ve learned along the way to get to
                            where they are. This
                            distance varies and is the overall sum of the situations and circumstance they’ve faced in
                            terms of social identities,
                            income, wealth, occupation, education, social capital, community, culture, and other
                            factors.</p>
                    </div>

                    <div class="score-panel">
                        <div class="score-panel-header">
                            <div class="score-level">
                                <b>{{ scoreData?.level | titlecase }}</b>
                                <span>{{ scoreType }}</span>
                            </div>

                        </div>
                        <div *ngIf="scoreData?.level" class="score-level-progress bg-green"
                            [ngClass]="scoreData?.level | lowercase"></div>
                    </div>
                    <div class="score-panel-description" [innerHTML]="scoreData?.partner_recommendation">
                    </div>
                    <div class="custom-footer">
                        <button nz-button nzBlock nzSize="large" [nzType]="'default'" nzShape="round"
                            (click)="handleCancelModal()"><span class="color-theme">Close</span></button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
    </div>
</nz-spin>