<div class="follow-up">

    <div class="flex-center" *ngIf="followUpMgr.questionsOnJob.length === 0">
        <div class="info-box">
            <h2 class="font-heading">Follow-Up Questions</h2>
            <p>You can ask follow-up questions to Candidates. To get started, click on Add Follow Up Questions.</p>
            <button (click)="openQuestionsModal()" nz-button nzType="primary" nzSize="large" nzShape="round" nzBlock>Go
                Add Follow Up Questions</button>
        </div>
    </div>
    <div *ngIf="followUpMgr.questionsOnJob.length > 0">
        <div class="job-questions">
            <div class="job-questions-header">
                <div class="question-title">
                    <span>Follow Up Questions</span>
                    <span class="question-number">{{followUpMgr.questionsOnJob.length}}</span>
                </div>
                <div>
                    <button nz-button nzType="default" nzSize="large" class="btn-gray" nzShape="round">Manage
                        Question(s)</button>
                </div>
            </div>

            <div class="job-questions-body">
                <div *ngFor="let category of followUpMgr.questionsOnJob">
                    <div class="question-title">
                        <span>{{ category.category }}</span>
                        <span class="question-number">{{ category.questions.length }}</span>
                    </div>
                    <div *ngFor="let question of category.questions; let i = index" class="editable-flex">
                        <div #editableText class="editable-text"
                            [attr.contenteditable]="question.isEditable ? 'true' : 'false'"
                            (blur)="saveUpdatedQuestion(category.id, category.category, editableText.textContent, question)">
                            {{
                            question.question }}</div>
                        <div class="editable-icon" (click)="toggleEdit(category.id, i)">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
                                    fill="#1C1A20" fill-opacity=".7"></path>
                            </svg>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <nz-modal [(nzVisible)]="followUpMgr.isVisibleJobQuestionsModal" nzTitle="Follow Up Questions"
        (nzOnCancel)="handleCancel()" [nzFooter]="null" nzWidth="868px"
        [nzBodyStyle]="{ 'height': '95vh', 'overflow-y': 'auto' }" [nzStyle]="{ top: '0' }" class="border-radius-none"
        [nzMaskClosable]="false">
        <ng-container *nzModalContent>
            <nz-spin [nzSpinning]="followUpMgr.isLoading">

                <form [formGroup]="form" class="flex-modal">
                    <div>
                        <div class="gray-text-box">
                            <h4 class="font-heading">Best Practices</h4>
                            <ol>
                                <li>Keep it concise: Limit the number of questions to 2-3 to streamline the candidate's
                                    response time and ensure they focus
                                    on the most relevant aspects of the job.</li>
                                <li>
                                    Focus on key competencies: Craft questions that directly assess the candidate's
                                    skills,
                                    experience, and qualities
                                    crucial for success in the role, aligning with the job requirements and company
                                    culture.
                                </li>
                                <li *ngIf="!isExpanded">
                                    Use the most simple question type to get the information you seek. Open-ended,
                                    experience-based and scenario-based questions are great for gaining a
                                    comprehensive...
                                </li>
                                <ng-container *ngIf="isExpanded">
                                    <li>
                                        Use the most simple question type to get the information you seek. Open-ended,
                                        experience-based and scenario-based questions are great for gaining a
                                        comprehensive understanding of the candidate's abilities, problem-solving
                                        skills,
                                        and cultural fit, but they take longer to answer.
                                    </li>
                                    <li>Consider using a rating (1-10), percentage splits, or options like “not at all”
                                        to
                                        very likely” to help the candidates
                                        be able to respond more quickly as well as to give you the ability to compare
                                        responses more fairly and consistently.</li>
                                    <li>Some successful sample questions from other partners include:</li>
                                    <li>When you consider sets of complementary traits, we are all a unique balance. For
                                        example, when thinking about being
                                        operational and strategic you might be 60% operational + 40% strategic, or maybe
                                        75%
                                        strategic + 25% operational.</li>
                                    <li>How would you describe yourself when it comes to each of the following three
                                        complementary sets of traits? (Be sure that
                                        they add up to 100)
                                        strategic and operational
                                        consistent and flexible
                                        structured and dynamic</li>
                                    <li>On a scale of 1 - 10, with 1 being not at all familiar and 10 being extremely
                                        familiar, how would you rate your
                                        familiarity with Google Workspace tools?</li>
                                    <li>
                                        Focus on key competencies: Craft questions that directly assess the candidate's
                                        skills, experience, and qualities
                                        crucial for success in the role, aligning with the job requirements and company
                                        culture.
                                        Use the most simple question type to get the information you seek.
                                        Open-ended, experience-based and scenario-based questions are great for gaining
                                        a
                                        comprehensive understanding of the
                                        candidate's abilities, problem-solving skills, and cultural fit, but they take
                                        longer to answer.
                                        Consider using a rating (1-10), percentage splits, or options like “not at all”
                                        to
                                        very likely” to help the candidates
                                        be able to respond more quickly as well as to give you the ability to compare
                                        responses more fairly and consistently.
                                        Some successful sample questions from other partners include:
                                        When you consider sets of complementary traits, we are all a unique balance. 
                                        For
                                        example, when thinking about being
                                        operational and strategic you might be 60% operational + 40% strategic, or maybe
                                        75%
                                        strategic + 25% operational.
                                        How would you describe yourself when it comes to each of the following three
                                        complementary sets of traits? (Be sure that
                                        they add up to 100)
                                        strategic and operational
                                        consistent and flexible
                                        structured and dynamic
                                        On a scale of 1 - 10, with 1 being not at all familiar and 10 being extremely
                                        familiar, how would you rate your
                                        familiarity with Google Workspace tools?
                                        Think of an instance from your professional or personal life where you
                                        successfully
                                        mediated between two team people who
                                        were experiencing a challenge in getting along.  Please briefly share 1-2
                                        techniques
                                        you used to alleviate the
                                        situation.
                                        On a scale of 1 - 10, with 1 being not at all effective and 10 being extremely
                                        effective, how would your last supervisor
                                        rate you on your ability to work with people across the range of experience from
                                        entry-level to senior management?</li>
                                    <li>On a scale of 1 - 10, with 1 being not at all effective and 10 being extremely
                                        effective, how would your last supervisor
                                        rate you on your ability to work with people across the range of experience from
                                        entry-level to senior management?</li>
                                </ng-container>
                            </ol>
                            <div class="button-container">
                                <button nz-button nzType="text" class="btn-dark" (click)="toggleReadMore()">{{
                                    isExpanded ?
                                    'Read Less' :
                                    'Read More' }}</button>
                            </div>
                        </div>
                        <p>Add custom questions to ask Candidates.</p>
                        <div *ngIf="!showEditable" class="button-container mb-24">
                            <button (click)="toggleEditable()" nz-button nzType="default" nzSize="large"
                                class="btn-gray" nzShape="round"><span nz-icon nzType="plus"
                                    nzTheme="outline"></span>Add New Follow-Up
                                Question</button>
                        </div>
                        <div class="editable-wrap" *ngIf="showEditable" formArrayName="followUpQuestions">
                            <div class="editable-item"
                                *ngFor="let question of followUpQuestions.controls; let i = index" [formGroupName]="i">
                                <div class="holder"><span nz-icon nzType="holder" nzTheme="outline"></span></div>
                                <div class="editable-body">
                                    <p>Follow Up Question:</p>
                                    <div nz-row [nzGutter]="16">
                                        <div nz-col [nzSpan]="8">
                                            <nz-form-control>
                                                <nz-select [nzSuffixIcon]="customSuffixIcon"
                                                    nzPlaceHolder="Select Category*" nzSize="large"
                                                    formControlName="category">
                                                    <nz-option nzLabel="Hard Skills" nzValue="Hard Skills"></nz-option>
                                                    <nz-option nzLabel="People Skills"
                                                        nzValue="People Skills"></nz-option>
                                                    <nz-option nzLabel="Education" nzValue="Education"></nz-option>
                                                    <nz-option nzLabel="Experience" nzValue="Experience"></nz-option>
                                                    <nz-option nzLabel="Other" nzValue="Other"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </div>
                                        <div nz-col [nzSpan]="14">
                                            <nz-form-control>
                                                <textarea formControlName="question" nz-input rows="1" nzSize="large"
                                                    placeholder="Enter question."></textarea>
                                            </nz-form-control>
                                        </div>
                                        <div nz-col [nzSpan]="2">
                                            <button class="remove-button" (click)="removeFollowUpQuestion(i)">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                                        fill="#1C1A20" fill-opacity=".7" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="add-button">
                                <button (click)="addFollowUpQuestion()" nz-button nzType="text"><span nz-icon
                                        nzType="plus" nzTheme="outline"></span>
                                    Add
                                    New Follow-Up Question</button>
                            </div>
                        </div>
                        <div class="note-light-bar">
                            <span>Send Follow-Up Questions to Candidates who have already responded to the
                                CLARA-generated
                                questions. <span class="ico-sm" nz-popover [nzPopoverContent]="contentTemplate"
                                    nzPopoverPlacement="topLeft" nz-icon nzType="info-circle"
                                    nzTheme="outline"></span></span>
                            <nz-switch [(ngModel)]="followUpMgr.statusFollowUpQuestions"
                                (ngModelChange)="changeNotificationsSettings(followUpMgr.statusFollowUpQuestions)"
                                [ngModelOptions]="{standalone: true}"></nz-switch>
                        </div>
                        <ng-template #contentTemplate>
                            <div class="info-popover">
                                Toggle this feature ON to have CLARA automatically send these additional Follow-Up
                                Questions
                                to Candidates who have
                                already applied. It is likely that they've received CLARA's initial set of real-time
                                generated questions.
                            </div>
                        </ng-template>
                    </div>
                    <div class="custom-footer">
                        <button (click)="onSubmitFollowUpQuestion()" nz-button nzBlock class="mb-10" nzSize="large"
                            nzType="primary" nzShape="round">Save</button>
                        <button nz-button nzBlock nzType="text" nzSize="large" (click)="handleCancel()">Cancel</button>
                    </div>
                </form>
            </nz-spin>

            <ng-template #customSuffixIcon>
                <span nz-icon nzType="caret-down" nzTheme="outline"></span>
            </ng-template>

        </ng-container>
    </nz-modal>

</div>