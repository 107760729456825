import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SjmService } from '../Services/sjm.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { resumeFinilizeData, jobDetail } from '../models/models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SjmManagerService {
  public uploadingJob: boolean = false;
  public uploadingResums: boolean = false;
  public finalizingJob: boolean = false;
  public finishingJob: boolean = false;
  public updateResumeProcess: boolean = false;
  public updatingMaterial: boolean = false;
  public validatingEmail: boolean = false;
  public submitingApplications: boolean = false;
  public isEmail: boolean = false;
  public appliationObjId = '';
  public sjmJobObjId = '';
  public description: string = '';
  public resumeFinilizeData: resumeFinilizeData[] = [];
  isLoadingJobDetail: boolean = false;
  public jobDetailSJMData!: jobDetail;
  public jobInfo!: jobDetail;

  constructor(
    private http: HttpClient,
    public sjmMgr: SjmService,
    private notification: NzNotificationService
  ) {}

  public uploadJobFile(formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.uploadingJob = true;
      this.sjmMgr.uploadJobFile(formData).subscribe({
        next: (c: any) => {
          this.sjmJobObjId = c['job_obj_id'];
          this.description = this.replaceEscapeSequences(c['description']);

          this.uploadingJob = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.uploadingJob = false;
          reject(error);
        },
      });
    });
  }
  public editDescription(formData: any, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.finalizingJob = true;
      this.sjmMgr.editDescription(formData, id).subscribe({
        next: (c: any) => {
          console.log('c', c);

          this.finalizingJob = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.finalizingJob = false;
          reject(error);
        },
      });
    });
  }
  private replaceEscapeSequences(str: string) {
    return str
      .replace(/\f/g, '<br>')
      .replace(/\n/g, '<br>')
      .replace(/\t/g, '&emsp;');
  }
  public patchAiSettings(data: any, sjmJobObjId: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.finishingJob = true;
      this.sjmMgr.patchAiSettings(data, sjmJobObjId).subscribe({
        next: (c: any) => {
          this.finishingJob = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.finishingJob = false;
          reject(error);
        },
      });
    });
  }
  public uploadResumes(payload: any[], jobObjectId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.uploadingResums = true;
      const uploadPromises = [];

      for (let i = 0; i < payload.length; i++) {
        const file = payload[i].file; // Access file from the payload object
        const process_status = payload[i].process_status; // Access process_status from the payload object
        uploadPromises.push(
          this.uploadSingleResume(file, process_status, jobObjectId)
        );
      }

      Promise.all(uploadPromises)
        .then(() => {
          this.uploadingResums = false;
          resolve();
        })
        .catch((error) => {
          this.notification.create('error', 'Error', error.error.error);
          this.uploadingResums = false;
          reject(error);
        });
    });
  }

  private uploadSingleResume(
    file: File,
    process_status: boolean,
    jobObjectId: string
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('process_status', process_status.toString()); // Convert boolean to string

    return new Promise<any>((resolve, reject) => {
      this.sjmMgr.uploadSingleResumeAPI(formData, jobObjectId).subscribe({
        next: (c: any) => {
          resolve(c);
        },
        error: (error: any) => {
          reject(error);
        },
      });
    });
  }

  public updateCandidateResumeProcess(
    data: any,
    sjmJobObjId: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.updateResumeProcess = true;
      this.sjmMgr.updateCandidateResumeProcess(data, sjmJobObjId).subscribe({
        next: (c: any) => {
          this.updateResumeProcess = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.updateResumeProcess = false;
          reject(error);
        },
      });
    });
  }
  public addMateriaAndLinks(data: any, sjmJobObjId: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.updatingMaterial = true;
      this.sjmMgr.addMateriaAndLinks(data, sjmJobObjId).subscribe({
        next: (c: any) => {
          this.updatingMaterial = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.updatingMaterial = false;
          reject(error);
        },
      });
    });
  }
  public getMaterialAndLinks(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingJobDetail = true;
      this.http
        .get<any>('assets/job_materials_and_links.json')
        .subscribe((data) => {
          this.jobDetailSJMData = data.response;
          console.log(this.jobDetailSJMData);
          this.isLoadingJobDetail = false;
          resolve();
        });
    });
  }
  public validateEmail(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.validatingEmail = true;
      this.sjmMgr.validateEmail(data).subscribe({
        next: (c: any) => {
          this.validatingEmail = false;
          this.isEmail = false;
          resolve();
        },
        error: (error: any) => {
          // this.notification.create('error', 'Error', error.error.error);
          this.validatingEmail = false;
          this.isEmail = true;
          reject(error);
        },
      });
    });
  }
  public uploadCanidateResumeAndProfile(
    data: any,
    job_obj_id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.submitingApplications = true;
      this.sjmMgr.uploadCanidateResumeAndProfile(data, job_obj_id).subscribe({
        next: (c: any) => {
          this.submitingApplications = false;
          console.log('Uploading resume', c);
          console.log('appliationObjId', c.data.application_obj_id);
          this.appliationObjId = c.data.application_obj_id;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.submitingApplications = false;
          reject(error);
        },
      });
    });
  }
  public uploadCanidateMaterials(
    uploadedMaterials: any[],
    job_obj_id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.submitingApplications = true;
      let uploadPromises = uploadedMaterials.map((material) => {
        return lastValueFrom(
          this.sjmMgr.uploadCanidateMaterials(
            material,
            job_obj_id,
            this.appliationObjId
          )
        );
      });

      Promise.all(uploadPromises)
        .then((results) => {
          this.submitingApplications = false;
          console.log('Uploaded all materials', results);
          resolve();
        })
        .catch((error) => {
          this.notification.create('error', 'Error', error.error.error);
          this.submitingApplications = false;
          reject(error);
        });
    });
  }
  public submitCandidateLinks(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.submitingApplications = true;
      this.sjmMgr.submitCandidateLinks(data, this.appliationObjId).subscribe({
        next: (c: any) => {
          this.submitingApplications = false;
          console.log('Uploading resume', c);
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.submitingApplications = false;
          reject(error);
        },
      });
    });
  }
  public getJobInformation(jobId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.sjmMgr.getJobInformation(jobId).subscribe((c: any) => {
        this.jobInfo = c.response;
        resolve();
      });
    });
  }
}
