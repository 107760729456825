<div class="page">
    <main class="main" *ngIf="sjmMgr.jobInfo">
        <div class="company-name">{{sjmMgr.jobInfo.partner_name}}</div>
        <div class="d-flex align-middle color-black">
            <h1>{{sjmMgr.jobInfo.title}}</h1>
            <div class="ml-10" *ngIf="jobObjectId">JOB ID
                {{jobObjectId}}</div>
        </div>
        <div class="white-panel">
            <div class="border-box">
                <p class="color-theme font-weight-medium text-center">Your application was received!</p>
                <p class="color-theme">We will follow up shortly with some follow-up questions based on your application
                    materials.</p>
                <p class="text-center">Check the spam folder if you do not receive an email within 5 minutes.</p>
                <div class="border-box-alert text-center">
                    <b>Join CLARA today to create a profile and review your appliaction materials</b>
                </div>
            </div>
            <div class="job-detail color-black-container">
                <div class="left-panel">
                    <p><b>About the job</b></p>
                    <div class="formated-description"
                        [innerHTML]="replaceEscapeSequences(sjmMgr.jobInfo.formatted_description ? sjmMgr.jobInfo.formatted_description :sjmMgr.jobInfo.description)">
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>