<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>AI Settings</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col nzXs="24" nzSm="12" nzMd="8" nzLg="8" nzXl="8">
                <div class="heading-flex">
                    <h1>AI Settings</h1>
                </div>
                <nz-tabset>
                    <nz-tab nzTitle="Variables">
                        <nz-spin [nzSpinning]="aiManager.isLoading">
                            <div class="sub-info-action">
                                <p>Set the weighted priority of scoring variables that the CLARA AI uses <br> to produce
                                    the
                                    Fit
                                    Score for
                                    candidates on a scale
                                    of 0-5.</p>
                                <button nz-button nzType="primary"
                                    (click)="editAiSettingsModal(aiManager.aisSettingsActive,'open')"
                                    nzShape="round">Edit</button>
                            </div>
                            <div class="description-list">
                                <div class="description-list-item" *ngFor="let data of aiManager.aisSettingsActive">
                                    <div class="description-list-term">{{getScoreName(data.name)}}</div>
                                    <div class="description-list-detail">
                                        <span class="btn-badge square">{{ data.value }}</span>
                                    </div>
                                </div>
                            </div>
                        </nz-spin>
                    </nz-tab>
                    <nz-tab nzTitle="De-Identification">
                        <div class="sub-info-action">
                            <p>De-Identification of Candidate information</p>
                            <button nz-button nzType="primary" (click)="openDeInModal(aiManager.deIdentificationData)"
                                nzShape="round">Edit</button>
                        </div>
                        <nz-spin [nzSpinning]="aiManager.isLoadingDeIndentification">
                            <div class="description-list">
                                <div class="description-list-item">
                                    <div class="description-list-term">De-identify Candidate Information</div>
                                    <div class="description-list-detail">
                                        <nz-space nzSize="large">
                                            <nz-tag>{{aiManager.deIdentificationData?.de_identify_name ?
                                                'ON' :
                                                'OFF'}}</nz-tag>
                                        </nz-space>
                                    </div>
                                </div>
                                <div class="description-list-item">
                                    <div class="description-list-term">De-identify education</div>
                                    <div class="description-list-detail">
                                        <nz-space nzSize="large">

                                            <nz-tag>{{aiManager.deIdentificationData?.de_identify_education ?
                                                'ON' :
                                                'OFF'}}</nz-tag>
                                        </nz-space>
                                    </div>
                                </div>
                                <div class="description-list-item">
                                    <div class="description-list-term">De-identify experience</div>
                                    <div class="description-list-detail">
                                        <nz-space nzSize="large">
                                            <nz-tag>{{aiManager.deIdentificationData?.de_identify_experience ?
                                                'ON' :
                                                'OFF'}}</nz-tag>
                                        </nz-space>
                                    </div>
                                </div>

                            </div>


                        </nz-spin>
                    </nz-tab>
                </nz-tabset>

            </div>
        </div>
        <nz-modal [(nzVisible)]="aiManager.isVisibleAiModal" nzTitle="Edit AI Scoring Variables"
            (nzOnCancel)="handleAiCancel()" [nzFooter]="null" [nzBodyStyle]="{ 'height': '95vh', 'overflow-y': 'auto' }"
            [nzStyle]="{ top: '0' }" nzWidth="768px" nzClassName="no-border-radius" [nzMaskClosable]="false">
            <ng-container *nzModalContent>


                <form [formGroup]="validateForm" class="center-form">
                    <div>
                        <div class="chart-graybox">
                            <apx-chart [series]="chartOptions.series!" [chart]="chartOptions.chart!"
                                [labels]="chartOptions.labels" [theme]="chartOptions.theme!"
                                [dataLabels]="chartOptions.dataLabels!" [plotOptions]="chartOptions.plotOptions!"
                                [stroke]="chartOptions.stroke!" [legend]="chartOptions.legend!"></apx-chart>
                        </div>
                        <div *ngIf="!isAddingVairablesBox">
                            <p>Adjust the weight for each of the following variables:</p>
                            <div nz-row [nzGutter]="16" *ngIf="!isAddingVairablesBox">
                                <div nz-col [nzSpan]="8" class="pt-8">
                                    <div *ngFor="let setting of aiManager.aisSettingsActive" class="custom-radio">
                                        <div>
                                            <span class="group-title">{{getScoreName(setting.name) }}</span>

                                        </div>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="16">
                                    <div class="field-label">
                                        <span>NOT IMPORTANT</span>
                                        <span>VERY IMPORTANT</span>
                                    </div>
                                    <div *ngFor="let setting of aiManager.aisSettingsActive" class="custom-radio">
                                        <div>
                                            <nz-radio-group (ngModelChange)="onRadioChange(setting.key, $event)"
                                                formControlName="{{ setting.key }}" nzSize="small">
                                                <label *ngFor="let option of getOptions()" nz-radio-button
                                                    [nzValue]="option">
                                                    {{ option }}
                                                </label>
                                            </nz-radio-group>
                                        </div>
                                    </div>
                                    <div class="text-right mb-20">
                                        <button nz-button nzSize="large" class="bg-gray font-normal" nzShape="round"
                                            (click)="addingVariableBox()"
                                            [disabled]="aiManager.aisSettingsInactive.length === 0">
                                            Add Matching Criteria
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form class="shadow-box" *ngIf="isAddingVairablesBox" (ngSubmit)="submitNewSettingsForm()">
                            <h4>Select Additional AI Matching Criteria</h4>
                            <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="updateCheckedSettings($event)">
                                <ul class="zero-variable">
                                    <ng-container *ngFor="let setting of aiManager.aisSettingsInactive">
                                        <!-- {{setting | json}} -->
                                        <li>
                                            <label nz-checkbox [nzValue]="setting">{{
                                                getScoreName(setting.name) }}</label>
                                        </li>
                                    </ng-container>
                                </ul>
                            </nz-checkbox-wrapper>
                            <div class="shadow-box-footer">
                                <button nz-button (click)="addingVariableBox()" nzShape="round" class="font-norma">
                                    Cancel
                                </button>
                                <button nz-button nzBlock nzSize="large" [nzType]="'primary'" class="ml-10"
                                    nzShape="round" type="submit">
                                    Add Changes
                                </button>
                            </div>
                        </form>
                    </div>

                    <div class="custom-footer">
                        <button nz-button nzBlock nzSize="large" [nzType]="'primary'" class="mb-10" nzShape="round"
                            (click)="submitAiSettings()" [disabled]="isAddingVairablesBox">
                            <span *ngIf="aiManager.isLoading" nz-icon nzType="loading" nzTheme="outline"></span>
                            Save and Recalculate Candidate Scores
                        </button>
                        <button nz-button nzType="link" nzBlock [disabled]="isAddingVairablesBox"
                            (click)="handleAiCancel()" nzShape="round">
                            Cancel
                        </button>
                    </div>
                </form>

            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="aiManager.isVisibleDeIndModal" nzTitle="De-Identification of Candidate information"
            (nzOnCancel)="handleDeInCancel()" [nzBodyStyle]="{ 'height': 'calc(100vh - 115px)', 'overflow-y': 'auto' }"
            [nzStyle]="{ top: '0' }" nzWidth="768px" nzClassName="no-border-radius" [nzFooter]="modalFooter"
            [nzMaskClosable]="false">
            <form [formGroup]="deIndentifyForm">
                <ng-container *nzModalContent>
                    <div class="settings-form">
                        <p>Set how the CLARA AI will de-identify candidate information.</p>
                        <div class="description-list">
                            <div class="description-list-item">
                                <div class="description-list-term">De-identify Candidate Information</div>
                                <div class="description-list-detail">
                                    <nz-space nzSize="large">
                                        <nz-switch formControlName="de_identify_name" [nzDisabled]="true"></nz-switch>
                                    </nz-space>
                                </div>
                            </div>
                            <div class="description-list-item">
                                <div class="description-list-term">De-identify education</div>
                                <div class="description-list-detail">
                                    <nz-space nzSize="large">
                                        <nz-switch formControlName="de_identify_education"></nz-switch>
                                    </nz-space>
                                </div>
                            </div>
                            <div class="description-list-item">
                                <div class="description-list-term">De-identify experience</div>
                                <div class="description-list-detail">
                                    <nz-space nzSize="large">
                                        <nz-switch formControlName="de_identify_experience"></nz-switch>
                                    </nz-space>
                                </div>
                            </div>
                        </div>
                        <div class="separator-lg"></div>
                        <p>NOTE:CLARA employs rigorous AI technology to power our de-identification initiative, but due
                            to the diverse range of
                            names and organizations, we cannot guarantee complete de-identification.</p>
                    </div>
                </ng-container>
                <ng-template #modalFooter>
                    <div class="custom-footer-right">
                        <button nz-button nzType="default" [disabled]="isAddingVairablesBox"
                            (click)="handleDeInCancel()" nzShape="round">
                            Cancel
                        </button>
                        <button nz-button nzSize="large" [nzType]="'primary'" nzShape="round"
                            (click)="submitDeIndentifySettings()" [disabled]="isAddingVairablesBox">
                            <span *ngIf="aiManager.isLoading" nz-icon nzType="loading" nzTheme="outline"></span>
                            Save Changes
                        </button>
                    </div>
                </ng-template>
            </form>

        </nz-modal>
    </main>
</div>