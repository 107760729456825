<nz-spin [nzSpinning]="jobsMgr.loadingJobRequirementList">
    <div class="panels">
        <div class="left-panel">
            <!-- <div class="action-right" *ngIf="jobsMgr.userJobAccessStatus === 'granted'">
                <button (click)="goToRefineJob()" nz-button nzType="default" nzShape="round"
                    class="font-size-medium mr-8">Refine
                    Job
                    Requirements</button>
            </div> -->
            <div class="access-frame" *ngIf="user.role === 'Recruiter' && jobsMgr.userJobAccessStatus != 'granted'">
                <span>To refine the jobs requirements you must be granted access.</span>
                <button nz-button nzType="default" nzShape="round" class="font-size-medium mr-8"
                    (click)="requestRefinement()"
                    [disabled]="jobsMgr.userJobAccessStatus === 'revoked' || jobsMgr.userJobAccessStatus === 'requested' || jobsMgr.checkingUserJobStatus">
                    <ng-container *ngIf="jobsMgr.checkingUserJobStatus; else buttonText">
                        <span nz-icon nzType="loading" nzTheme="outline"></span>
                    </ng-container>
                    <ng-template #buttonText>
                        {{ jobsMgr.userJobAccessStatus === 'not-requested' ? 'Request Access' : 'Request Sent!' }}
                    </ng-template>
                </button>
            </div>

            <div class="gray-box" *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }">
                <p><strong>Edit Job Requirements</strong></p>
                <p>Editing the job requirements will trigger a re-processing of all candidates for this job, which
                    may
                    take some time (we
                    process an average of 3 candidates per minute).</p>
                <div class="d-flex justify-content-end">
                    <button nz-button nzType="default" nzShape="round" *ngIf="!isEditMode"
                        (click)="editJobRequirements()">
                        <span nz-icon nzType="edit" nzTheme="outline"></span> Edit Job Requirements
                    </button>
                    <button nz-button nzType="default" nzShape="round" *ngIf="isEditMode" class="mr-8"
                        (click)="editJobRequirements()">
                        Discard changes
                    </button>
                    <button nz-button nzType="primary" nzShape="round" (click)="submitJobRequirements()"
                        *ngIf="isEditMode"><span *ngIf="jobsMgr.updatingJobRequirementList" nz-icon nzType="loading"
                            nzTheme="outline"></span>
                        Save changes
                    </button>
                </div>

            </div>

            <div *ngIf="jobsMgr.variables && !isEditMode">
                <!-- Responsibilities Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.responsibilities.length > 0">
                    <p><strong>Responsibilities</strong></p>
                    <ul>
                        <li *ngFor="let responsibility of jobsMgr.variables.responsibilities"
                            [ngClass]="{'disabled': responsibility.status === 'hide'}">
                            {{ responsibility.description }}
                            <div *ngIf="responsibility.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>

                <!-- Required Experience Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.required_experience.length > 0">
                    <p><strong>Required Experience</strong></p>
                    <ul>
                        <li *ngFor="let experience of jobsMgr.variables.required_experience"
                            [ngClass]="{'disabled': experience.status === 'hide'}">
                            {{ experience.description }}
                            <div *ngIf="experience.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>

                <!-- Nice to Have Experience Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.nice_to_have_experience.length > 0">
                    <p><strong>Nice to Have Experience</strong></p>
                    <ul>
                        <li *ngFor="let niceToHave of jobsMgr.variables.nice_to_have_experience"
                            [ngClass]="{'disabled': niceToHave.status === 'hide'}">
                            {{ niceToHave.description }}
                            <div *ngIf="niceToHave.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>

                <!-- Required Skills Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.required_skills.length > 0">
                    <p><strong>Required Skills</strong></p>
                    <ul>
                        <li *ngFor="let skill of jobsMgr.variables.required_skills"
                            [ngClass]="{'disabled': skill.status === 'hide'}">
                            {{ skill.description }}
                            <div *ngIf="skill.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>

                <!-- Nice to Have Skills Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.nice_to_have_skills.length > 0">
                    <p><strong>Nice to Have Skills</strong></p>
                    <ul>
                        <li *ngFor="let niceSkill of jobsMgr.variables.nice_to_have_skills"
                            [ngClass]="{'disabled': niceSkill.status === 'hide'}">
                            {{ niceSkill.description }}
                            <div *ngIf="niceSkill.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>
                <!-- People Skills Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.people_skills.length > 0">
                    <p><strong>People Skills</strong></p>
                    <ul>
                        <li *ngFor="let peopleSkill of jobsMgr.variables.people_skills"
                            [ngClass]="{'disabled': peopleSkill.status === 'hide'}">
                            {{ peopleSkill.description }}
                            <div *ngIf="peopleSkill.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>
                <!-- Education Section -->
                <div class="variable-item" *ngIf="jobsMgr.variables.education">
                    <p><strong>Education</strong></p>
                    <ul>
                        <li [ngClass]="{'disabled': jobsMgr.variables.education.status === 'hide'}">
                            {{ jobsMgr.variables.education.degree }}
                            <div *ngIf="jobsMgr.variables.education.user_added" class="variable-type">User Added</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="isEditMode">
                <form [formGroup]="jobForm">
                    <!-- Responsibilities Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>Responsibilities</strong>
                            <button type="button" class="ico-icon lg" (click)="addResponsibility()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>
                        <div formArrayName="responsibilities">
                            <div *ngFor="let control of responsibilities.controls; let i = index">
                                <div class="editable-item" [formGroupName]="i">
                                    <div class="fields-status"
                                        [ngClass]="{'disabled-item': control.get('status')?.value === 'hide'}">

                                        <div *ngIf="control.get('user_added')?.value; else textOnly">
                                            <textarea nz-input rows="1" [formControlName]="'description'"
                                                placeholder="Add Description" nzSize="large"></textarea>
                                        </div>
                                        <ng-template #textOnly>
                                            <span class="text-field">{{control.get('description')?.value}}</span>
                                        </ng-template>


                                        <button class="tag-button">
                                            <span *ngIf="control.get('user_added')?.value; else originalRequirement">
                                                User-added
                                            </span>
                                            <ng-template #originalRequirement>
                                                Original job requirement
                                            </ng-template>
                                        </button>
                                    </div>
                                    <button type="button" class="ico-icon sm" (click)="removeResponsibility(i)"
                                        *ngIf="control.get('user_added')?.value">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>

                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'active'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'hide'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Required Experience Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>Required Experience</strong>
                            <button type="button" class="ico-icon lg" (click)="addRequiredExperience()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>
                        <div formArrayName="requiredExperience">
                            <div *ngFor="let control of requiredExperience.controls; let i = index">
                                <div class="editable-item" [formGroupName]="i">
                                    <div class="fields-status"
                                        [ngClass]="{'disabled-item': control.get('status')?.value === 'hide'}">

                                        <div *ngIf="control.get('user_added')?.value; else textOnly">
                                            <textarea nz-input rows="1" [formControlName]="'description'"
                                                placeholder="Add Description" nzSize="large"></textarea>
                                        </div>
                                        <ng-template #textOnly>
                                            <span class="text-field">{{control.get('description')?.value}}</span>
                                        </ng-template>


                                        <button class="tag-button">
                                            <span *ngIf="control.get('user_added')?.value; else originalRequirement">
                                                User-added
                                            </span>
                                            <ng-template #originalRequirement>
                                                Original job requirement
                                            </ng-template>
                                        </button>
                                    </div>
                                    <button type="button" class="ico-icon sm" (click)="removedRequiredExperience(i)"
                                        *ngIf="control.get('user_added')?.value">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>

                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'active'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'hide'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Nice to Have Experience Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>Nice to Have Experience</strong>
                            <button type="button" class="ico-icon lg" (click)="addNiceToHaveExperience()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>
                        <div formArrayName="niceToHaveExperience">
                            <div *ngFor="let control of niceToHaveExperience.controls; let i = index">
                                <div class="editable-item" [formGroupName]="i">
                                    <div class="fields-status"
                                        [ngClass]="{'disabled-item': control.get('status')?.value === 'hide'}">

                                        <div *ngIf="control.get('user_added')?.value; else textOnly">
                                            <textarea nz-input rows="1" [formControlName]="'description'"
                                                placeholder="Add Description" nzSize="large"></textarea>
                                        </div>
                                        <ng-template #textOnly>
                                            <span class="text-field">{{control.get('description')?.value}}</span>
                                        </ng-template>


                                        <button class="tag-button">
                                            <span *ngIf="control.get('user_added')?.value; else originalRequirement">
                                                User-added
                                            </span>
                                            <ng-template #originalRequirement>
                                                Original job requirement
                                            </ng-template>
                                        </button>
                                    </div>
                                    <button type="button" class="ico-icon sm" (click)="removeNiceToHaveExperience(i)"
                                        *ngIf="control.get('user_added')?.value">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>

                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'active'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'hide'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Required Skills Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>Required Skills</strong>
                            <button type="button" class="ico-icon lg" (click)="addRequiredSkill()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>
                        <div formArrayName="requiredSkills">
                            <div *ngFor="let control of requiredSkills.controls; let i = index">
                                <div class="editable-item" [formGroupName]="i">
                                    <div class="fields-status"
                                        [ngClass]="{'disabled-item': control.get('status')?.value === 'hide'}">

                                        <div *ngIf="control.get('user_added')?.value; else textOnly">
                                            <textarea nz-input rows="1" [formControlName]="'description'"
                                                placeholder="Add Description" nzSize="large"></textarea>
                                        </div>
                                        <ng-template #textOnly>
                                            <span class="text-field">{{control.get('description')?.value}}</span>
                                        </ng-template>


                                        <button class="tag-button">
                                            <span *ngIf="control.get('user_added')?.value; else originalRequirement">
                                                User-added
                                            </span>
                                            <ng-template #originalRequirement>
                                                Original job requirement
                                            </ng-template>
                                        </button>
                                    </div>
                                    <button type="button" class="ico-icon sm" (click)="removeRequiredSkill(i)"
                                        *ngIf="control.get('user_added')?.value">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>

                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'active'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'hide'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Nice to Have Skills Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>Nice to Have Skills</strong>
                            <button type="button" class="ico-icon lg" (click)="addNiceToHaveSkill()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>
                        <div formArrayName="niceToHaveSkills">
                            <div *ngFor="let control of niceToHaveSkills.controls; let i = index">
                                <div class="editable-item" [formGroupName]="i">
                                    <div class="fields-status"
                                        [ngClass]="{'disabled-item': control.get('status')?.value === 'hide'}">

                                        <div *ngIf="control.get('user_added')?.value; else textOnly">
                                            <textarea nz-input rows="1" [formControlName]="'description'"
                                                placeholder="Add Description" nzSize="large"></textarea>
                                        </div>
                                        <ng-template #textOnly>
                                            <span class="text-field">{{control.get('description')?.value}}</span>
                                        </ng-template>


                                        <button class="tag-button">
                                            <span *ngIf="control.get('user_added')?.value; else originalRequirement">
                                                User-added
                                            </span>
                                            <ng-template #originalRequirement>
                                                Original job requirement
                                            </ng-template>
                                        </button>
                                    </div>
                                    <button type="button" class="ico-icon sm" (click)="removeNiceToHaveSkill(i)"
                                        *ngIf="control.get('user_added')?.value">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>

                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'active'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'hide'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- People Skills Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>People Skills</strong>
                            <button type="button" class="ico-icon lg" (click)="addPeopleSkill()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>
                        <div formArrayName="peopleSkills">
                            <div *ngFor="let control of peopleSkills.controls; let i = index">
                                <div class="editable-item" [formGroupName]="i">
                                    <div class="fields-status"
                                        [ngClass]="{'disabled-item': control.get('status')?.value === 'hide'}">

                                        <div *ngIf="control.get('user_added')?.value; else textOnly">
                                            <textarea nz-input rows="1" [formControlName]="'description'"
                                                placeholder="Add Description" nzSize="large"></textarea>
                                        </div>
                                        <ng-template #textOnly>
                                            <span class="text-field">{{control.get('description')?.value}}</span>
                                        </ng-template>


                                        <button class="tag-button">
                                            <span *ngIf="control.get('user_added')?.value; else originalRequirement">
                                                User-added
                                            </span>
                                            <ng-template #originalRequirement>
                                                Original job requirement
                                            </ng-template>
                                        </button>
                                    </div>
                                    <button type="button" class="ico-icon sm" (click)="removePeopleSkill(i)"
                                        *ngIf="control.get('user_added')?.value">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>

                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'active'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                    <button type="button" class="ico-icon sm"
                                        *ngIf="!control.get('user_added')?.value && control.get('status')?.value === 'hide'"
                                        (click)="toggleStatus(control)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Education Section -->
                    <div class="editable-wrap">
                        <div class="add-button">
                            <strong>Education</strong>

                            <!-- Show Add Button if there's no degree in education -->
                            <button *ngIf="!jobForm.get('education.degree')?.value" type="button" class="ico-icon lg"
                                (click)="addEducation()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6z" fill="#1C1A20" fill-opacity=".6" />
                                </svg>
                            </button>
                        </div>

                        <!-- Show the education form only if the degree exists -->
                        <div [formGroup]="jobForm" *ngIf="jobForm.get('education.degree')?.value">
                            <div class="editable-item" [formGroupName]="'education'">
                                <div class="fields-status"
                                    [ngClass]="{'disabled-item': jobForm.get('education.status')?.value === 'hide'}">

                                    <!-- Conditional rendering based on user_added -->
                                    <div *ngIf="jobForm.get('education.user_added')?.value; else textOnlyEducation">
                                        <textarea nz-input rows="1" [formControlName]="'degree'"
                                            placeholder="Add Degree" nzSize="large"></textarea>
                                    </div>
                                    <ng-template #textOnlyEducation>
                                        <span class="text-field">{{ jobForm.get('education.degree')?.value }}</span>
                                    </ng-template>


                                    <!-- Status Button -->
                                    <button class="tag-button">
                                        <span
                                            *ngIf="jobForm.get('education.user_added')?.value; else originalEducationRequirement">
                                            User-added
                                        </span>
                                        <ng-template #originalEducationRequirement>
                                            Original job requirement
                                        </ng-template>
                                    </button>
                                </div>

                                <!-- Status Toggle Buttons -->
                                <button type="button" class="ico-icon sm"
                                    *ngIf="jobForm.get('education.status')?.value === 'active'"
                                    (click)="toggleStatus(jobForm.get('education'))">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10 2.502c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.523 7.5-9.016 7.5s-8.232-3.233-9.016-7.5c.783-4.267 4.523-7.5 9.016-7.5zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.835zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166z"
                                            fill="#1C1A20" fill-opacity=".6" />
                                    </svg>
                                </button>
                                <button type="button" class="ico-icon sm"
                                    *ngIf="jobForm.get('education.status')?.value === 'hide'"
                                    (click)="toggleStatus(jobForm.get('education'))">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.902 16.083A9.125 9.125 0 0 1 10 17.503c-4.493 0-8.232-3.234-9.016-7.5a9.152 9.152 0 0 1 2.783-5.056L1.16 2.342l1.18-1.179 16.498 16.5-1.179 1.178-2.758-2.758zM4.946 6.127a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.171-5.171l-1.88-1.879zm5.816 5.815L8.06 9.241a2.083 2.083 0 0 0 2.7 2.7l.002.001zm6.577 1.887-1.192-1.192a7.446 7.446 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.451L6.645 3.136A9.157 9.157 0 0 1 10 2.502c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.768 6.26h.001z"
                                            fill="#1C1A20" fill-opacity=".6" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                </form>
                <div class="footer-actions"
                    *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }">

                    <div class="d-flex justify-content-end">
                        <button nz-button nzType="default" nzShape="round" *ngIf="isEditMode" class="mr-8"
                            (click)="editJobRequirements()">
                            Discard changes
                        </button>
                        <button nz-button nzType="primary" nzShape="round" (click)="submitJobRequirements()"
                            *ngIf="isEditMode"><span *ngIf="jobsMgr.updatingJobRequirementList" nz-icon nzType="loading"
                                nzTheme="outline"></span>
                            Save changes
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <div class="right-panel" *ngIf="jobsMgr.variables">
            <div class="right-panel-header">
                <div>Original Job Posting</div>
                <button type="button" class="ico-icon lg" (click)="viwePDFModal()"><svg width="24" height="24"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"
                            fill="#1C1A20" fill-opacity=".6" />
                    </svg>
                </button>
            </div>
            <div *ngIf="jobsMgr.variables && jobsMgr.variables.job_file_link" class="pdf-mini loader-placeholder">
                <pdf-viewer class="pdf-viewer" [src]="jobsMgr.variables.job_file_link" [rotation]="0"
                    [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1"
                    [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
                    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                    style="width: 100%; height: 448px;"></pdf-viewer>

            </div>
            <div class="user-card-subheader">
                <h4>Details</h4>
                <ul>
                    <li>
                        <span>Created</span>
                        <span>{{posting_date | date}}</span>
                    </li>
                    <li>
                        <span>ID</span>
                        <span>{{jobObjectId}}</span>
                    </li>
                    <li>
                </ul>
            </div>
        </div>
    </div>
    <nz-modal [(nzVisible)]="isVisiblePDF" nzTitle="Original Job Posting" (nzOnCancel)="handleOk()" [nzFooter]="null"
        nzWidth="968px" [nzBodyStyle]="{ 'height': 'calc(100vh - 60px)', 'overflow-y': 'auto' }"
        [nzStyle]="{ top: '0' }" nzClassName="no-border-radius" [nzMaskClosable]="false">
        <ng-container *nzModalContent>
            <div *ngIf="jobsMgr.variables && jobsMgr.variables.job_file_link" class="pdf-full loader-placeholder">
                <pdf-viewer class="pdf-viewer" [src]="jobsMgr.variables.job_file_link" [rotation]="0"
                    [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1"
                    [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
                    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                    style="width: 100%; height: calc(100vh - 60px);"></pdf-viewer>

            </div>
        </ng-container>
    </nz-modal>
</nz-spin>