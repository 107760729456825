import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-follow-up-questions',
  templateUrl: './follow-up-questions.component.html',
  styleUrl: './follow-up-questions.component.scss',
})
export class FollowUpQuestionsComponent {
  validateForm!: UntypedFormGroup;
  formGroup!: FormGroup;
  applicationId: string = '';
  age: string = '';
  token: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  currentStep = 0;
  user: any;
  isVisbileMaterial: boolean = false;
  isSkipAdditional: boolean = false;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public followUpMgr: FollowUpQuestionsManagerService
  ) {}
  replaceEscapeSequences(html: any): any {
    if (html == null) {
      return '';
    }
    return html
      .replace(/\\n/g, '<br />') // Replace newlines with <br>
      .replace(/\n/g, '<br />') // Replace literal newlines with <br>
      .replace(/\\f/g, '') // Remove form feed characters
      .replace(/\f/g, '') // Remove literal form feed
      .replace(/\\t/g, '&emsp;') // Replace tab with spaces
      .replace(/\t/g, '&emsp;') // Replace literal tab with spaces
      .replace(/\\u00a0/g, '&nbsp;') // Non-breaking space
      .replace(/\\u0026/g, '&amp;') // Ampersand
      .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>') // Bold for text between **
      .replace(/[^\x00-\x7F]/g, ''); // Remove non-ASCII characters
  }

  openQuestionsModal(data: any): void {
    this.followUpMgr.isVisibleQuestionsModal = true;
    this.initForm(data);
  }
  handleCancel(): void {
    this.followUpMgr.isVisibleQuestionsModal = false;
  }
  cancelSuccessModal(): void {
    this.followUpMgr.successModal = false;
  }
  cancelMaterialModal(): void {
    this.isVisbileMaterial = false;
  }
  visibleMaterialModal(): void {
    this.isVisbileMaterial = true;
  }
  handleCancelSubmitModal(): void {
    this.followUpMgr.isVisbileConfirmSubmit = false;
  }
  joinDein(): void {
    this.router.navigate(['/create-account'], {
      queryParams: {
        token: this.token,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
      },
    });
  }
  backToHome(): void {
    this.router.navigate(['/']);
  }
  goToStep(index: number): void {
    console.log('goToStep', index);
    this.currentStep = index;
  }

  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
  skipAdditional(): void {
    if (this.currentStep < this.followUpMgr.questions.length + 1) {
      this.currentStep++;
      this.isSkipAdditional = true;
    } else {
      console.log('else skip');
    }
  }
  nextStep(): void {
    if (this.currentStep < this.followUpMgr.questions.length + 1) {
      this.currentStep++;
    } else {
      console.log('else');
      this.followUpMgr.isVisbileConfirmSubmit = true;
    }
  }
  submitAnswers(): void {
    const payload = {
      answers: this.getAnswers(),
      additional_insights: this.isSkipAdditional
        ? []
        : this.getAdditionalInsights(),
    };
    console.log('Payload to submit:', payload);
    // Now submit the payload to your service or handle it as needed
    this.followUpMgr.submitFollowUpQuestions(payload, this.applicationId);
  }
  getAnswers(): Array<{ id: number; answer: string }> {
    return this.followUpMgr.questions
      .map((item) => {
        return item.questions.map((question) => {
          const answer = this.formGroup.get('question_' + question.id)?.value;
          if (answer && answer.trim() !== '') {
            return {
              id: question.id,
              answer: answer,
            };
          }
          return null; // Return null for empty answers
        });
      })
      .flat()
      .filter(
        (answer): answer is { id: number; answer: string } => answer !== null
      ); // Type guard to filter out null
  }
  getAdditionalInsights(): Array<{ question_id: string; answers: string[] }> {
    return this.followUpMgr.additional_insights
      .map((insight) => {
        const answer = this.formGroup.get('question_' + insight._id)?.value;
        if (answer && answer.trim() !== '') {
          return {
            question_id: insight._id,
            answers: [answer],
          };
        }
        return null; // Return null for empty answers
      })
      .filter(
        (insight): insight is { question_id: string; answers: string[] } =>
          insight !== null
      ); // Type guard for filtering
  }

  cancelFollowUpQuestion(): void {
    this.followUpMgr.isVisibleQuestionsModal = false;
  }

  sumbitPayload(data: any): void {
    let payload: any = {
      answers: [],
    };
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const id = parseInt(key.split('_')[1]);
        payload.answers.push({ id: id, answer: data[key] });
      }
    }
    return payload;
  }

  initForm(data: any): void {
    const formControls: any = {};
    for (const category of data) {
      for (const question of category.questions) {
        formControls['question_' + question.id] = [question.answer];
      }
    }
    // Initialize form controls for additional insights (radio buttons)
    for (const insight of this.followUpMgr.additional_insights) {
      formControls['question_' + insight._id] = insight.answers
        ? insight.answers[0]
        : [null];
    }
    this.formGroup = this.fb.group(formControls);
  }
  onInputAnswer(event: Event): void {
    if (this.followUpMgr.isAllAnswered) {
      event.preventDefault();
      const inputElement = event.target as HTMLTextAreaElement;
      inputElement.value = inputElement.value.slice(0, -1);
    }
  }
  getSelectedOption(question: any): string | null {
    const selectedNumber = this.formGroup.get(
      'question_' + question._id
    )?.value;
    const selectedOption = question.options.find(
      (option: { number: any }) => option.number === selectedNumber
    );
    return selectedOption ? selectedOption.option : null;
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.token = params['token'];
      this.firstName = params['first_name'];
      this.lastName = params['last_name'];
      this.email = params['email'];
      this.applicationId = params['application_id'];
      this.age = params['age'];
      await this.followUpMgr.getFollowUpQuestions(params['application_id']);
    });
  }
}
